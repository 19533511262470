<template>
  <div>
    <b-toast id="quote-toast" toaster='b-toaster-bottom-right' :visible='shouldShow' no-auto-hide solid>
      <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Quote Request</strong>
      </div>
      <p class="h6">
        You are interseted in {{ count }} product{{ count > 1 ? 's' : ''}}.
        <br/><br/>
        Click finish below to complete your quote request.
      </p>

      <b-row align-h="end">
        <b-button class='float-right mr-4' variant="primary" href='/quote'>Finish</b-button>
      </b-row> 
    </b-toast>
  </div>
</template>

<script>
  export default {
    computed: {
      count () {
        return this.$store.state.quotes.products.length
      },
      shouldShow () {
        return this.count > 0 && !_.startsWith(window.location.pathname, '/quote')
      }
    }
  }
</script>
