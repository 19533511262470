/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.
import Vue from 'vue';
import QuoteList from './components/quotes/quote_list.vue'
import QuoteButton from './components/quotes/quote_button.vue'
import QuoteToast from './components/quotes/quote_toast.vue'
import store from './store'
import 'bootstrap-vue/dist/bootstrap-vue.css'

const event = (typeof Turbolinks == "object" && Turbolinks.supported) ? "turbolinks:load" : 'DOMContentLoaded'

const components =  {'quote-button': QuoteButton, 'quote-list': QuoteList, 'quote-toast': QuoteToast};

document.addEventListener(event, () => {
  const app = new Vue({
    el: '#main',
    store,
    components: components,
  })
  console.log(app)
})
