// initial state
const state = {
  products: []
}

// getters
const getters = {
  isProductAdded: (state) => (product) => {
    return _.findIndex(state.products, function(p) {
      return p['id'] == product['id']
    }) > -1
  }
}

// actions
const actions = {
  removeProduct ({ commit }, product) {
    commit('removeProduct', product)
  },
  toggleProduct ({ commit }, product) {
    if (this.getters['quotes/isProductAdded'](product)) {
      commit('removeProduct', product)
    } else {
      commit('addProduct', product)
    }
  },
}

// mutations
const mutations = {
  addProduct (state, product) {
    state.products.push(product)
  },
  removeProduct (state, product) {
    state.products.splice(state.products.indexOf(product), 1)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
