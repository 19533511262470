import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistedstate from 'vuex-persistedstate'
import BootstrapVue from 'bootstrap-vue'
import quotes from './modules/quotes'
import _ from "lodash";

Vue.use(Vuex)
Vue.use(BootstrapVue)

const store = new Vuex.Store({
  modules: {
    quotes,
  },
  strict: process.env.NODE_ENV !== 'production',
  plugins: [VuexPersistedstate()]
})

export default store
