<template>
  <button 
    @click='click' 
    class="btn col-sm-12"
    v-bind:class="classObject"
    v-on:mouseover="mouseover"
    v-on:mouseleave="mouseleave">
    {{ text }}
  </button>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['product'],
  data: function () {
    return {
      isHovering: false,
      text: 'Get Quote'
    }
  },
  computed: {
    ...mapGetters({
      isProductAdded: 'quotes/isProductAdded'
    }),
    buttonText () {
      return this.isProductAdded(this.product) ? 'Added' : 'Get Quote'
    },
    classObject: function () {
      if (this.isProductAdded(this.product)) {
        return this.isHovering ? 'btn-danger' : 'btn-success'
      }
      return 'btn-primary'
    }
  },
  created: function () {
    this.text = this.buttonText
  },
  methods: {
    ...mapActions({
      toggleProduct: 'quotes/toggleProduct'
    }),
    click: function(){
      this.toggleProduct(this.product)
      this.text = this.buttonText
    },   
    mouseover: function(){
      if (this.isProductAdded(this.product)) {
        this.isHovering = true
        this.text = 'Remove'
      }
    },    
    mouseleave: function(){
      this.isHovering = false
      this.text = this.buttonText
    }
  }
}
</script>

<style scoped></style>
