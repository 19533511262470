<template>
  <div class="col-sm-6" v-if="hasProducts">
		<div class="card">
			<div class="card-body">
        <h4>Products</h4>
        <ul id="quote-list" class="list-unstyled">
          <li v-for="product in products" v-bind:key="product.id" class="mt-4">
            <input multiple="multiple" :value="product.id" type="hidden" name="quote[product_ids][]" />
            {{ product.title }} <a @click='removeProduct(product)' class='btn btn-sm btn-danger text-white float-right'>Remove</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      products: state => state.quotes.products
    }),
    hasProducts () {
      return this.products.length > 0
    },
  },
  methods: {
    ...mapActions({
      removeProduct: 'quotes/removeProduct'
    }),
  },
}
</script>

<style scoped></style>
